import React from 'react';
import './Toggle.scss';

interface ToggleProps {
  defaultValue: boolean;
  labels: [string, string];
  values: [string, string];
  onChange: (checked: boolean, value: string) => void;
  disabled?: boolean;
}

export function Toggle({
  defaultValue,
  labels,
  values,
  onChange,
  disabled = false,
}: ToggleProps) {
  const [checked, setChecked] = React.useState(defaultValue);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked, values[newChecked ? 1 : 0]);
  };

  return (
    <div className="toggle-container">
      <label className={`toggle ${disabled ? 'disabled' : ''}`}>
        <span className="toggle-label">{labels[checked ? 1 : 0]}</span>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="toggle-switch" />
      </label>
    </div>
  );
}
