/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { useCallback, useEffect, useRef, useState } from 'react';

import { RealtimeClient } from '@frangiskos/realtime-api-beta';
import type { ItemType } from '@frangiskos/realtime-api-beta/dist/lib/client.js';
import { ArrowDown, ArrowUp, Settings, X, Zap } from 'react-feather';

import { Button } from '../components/button/Button';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Toggle } from '../components/toggle/Toggle';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import './ConsolePage.scss';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

// Add new interfaces for detailed token tracking
interface TokenDetails {
  cached: number;
  text: number;
  audio: number;
}

interface TokenCounts {
  input: TokenDetails;
  output: TokenDetails;
}

interface TokenCosts {
  input: number;
  output: number;
  total: number;
}

export function ConsolePage() {
  /**
   * Ask user for API Key
   * If we're using the local relay server, we don't need this
   */
  const apiKey =
    'sk-svcacct-IPEB-t7CXxL3jmb3HDxNC8hWkOjTJ0Z5T_Tjo_UUz4RjNBnl_KPjS-in7xhEjrvCriDiwIT3BlbkFJSVbtFohIBG1EF6kqeiF6SEIiUNghHyGjyoRHkXUEW8XFj3m35uu-b983ebsOEbsnw_2fUA';

  // const apiKey = '';
  //   ? ''
  //   : localStorage.getItem('tmp::voice_api_key') ||
  //     prompt('OpenAI API Key') ||
  //     '';
  // if (apiKey !== '') {
  //   localStorage.setItem('tmp::voice_api_key', apiKey);
  // }

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : // : {
          //     apiKey: apiKey,
          //     dangerouslyAllowAPIKeyInBrowser: true,
          //   }
          {
            apiKey:
              'BGiy68sMlFeyL3jdyVWCkfKxgWPQk6F0ccMkATelqkMZHtPZoDkoJQQJ99ALACHYHv6XJ3w3AAAAACOG2Pf0',
            dangerouslyAllowAPIKeyInBrowser: true,
            url: 'https://ai-frangiskos8836ai770855298430.openai.azure.com/openai/realtime?api-version=2024-10-01-preview&deployment=gpt-4o-realtime-preview',
          }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [selectedMicrophone, setSelectedMicrophone] =
    useState<MediaDeviceInfo | null>(null);
  const [availableMicrophones, setAvailableMicrophones] = useState<
    MediaDeviceInfo[]
  >([]);

  // Update token state to track detailed counts
  const [tokenCounts, setTokenCounts] = useState<TokenCounts>({
    input: { cached: 0, text: 0, audio: 0 },
    output: { cached: 0, text: 0, audio: 0 },
  });

  // Add state for costs
  const [costs, setCosts] = useState<TokenCosts>({
    input: 0,
    output: 0,
    total: 0,
  });

  // Constants for pricing (€ per 1M tokens)
  const PRICES = {
    TEXT_INPUT: 5.2471,
    TEXT_INPUT_CACHED: 2.3851,
    TEXT_OUTPUT: 20.988361,
    AUDIO_INPUT: 104.9419,
    AUDIO_INPUT_CACHED: 20.9884,
    AUDIO_OUTPUT: 209.88361,
  };

  // Function to calculate costs
  const calculateCosts = useCallback((counts: TokenCounts): TokenCosts => {
    const inputCost =
      (counts.input.text * PRICES.TEXT_INPUT) / 1_000_000 +
      (counts.input.cached * PRICES.TEXT_INPUT_CACHED) / 1_000_000 +
      (counts.input.audio * PRICES.AUDIO_INPUT) / 1_000_000;

    const outputCost =
      (counts.output.text * PRICES.TEXT_OUTPUT) / 1_000_000 +
      (counts.output.audio * PRICES.AUDIO_OUTPUT) / 1_000_000;

    return {
      input: inputCost,
      output: outputCost,
      total: inputCost + outputCost,
    };
  }, []);

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone with selected device
    await wavRecorder.begin(selectedMicrophone?.deviceId);

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hello!`,
      },
    ]);

    // Set VAD mode by default
    client.updateSession({
      turn_detection: { type: 'server_vad' },
    });

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, [selectedMicrophone]);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setTokenCounts({
      input: { cached: 0, text: 0, audio: 0 },
      output: { cached: 0, text: 0, audio: 0 },
    });
    setCosts({ input: 0, output: 0, total: 0 });

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set voice to "alloy" | "echo" | "shimmer". If you don't set a voice, the instructions will be ignored!
    // "alloy" does not speak Greek!
    // "shimmer" messes up the transcriptions!
    client.updateSession({ voice: 'echo' });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      // Add console log to debug events
      console.log(
        'Realtime event:',
        realtimeEvent.event.type,
        realtimeEvent.event
      );

      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });

      // Token counting logic
      if (realtimeEvent.event.type === 'transcript.response') {
        const usage = realtimeEvent.event.usage;
        console.log('Transcript usage:', usage);

        if (usage) {
          setTokenCounts((prev) => {
            const newCounts = {
              input: {
                cached:
                  prev.input.cached +
                  (usage.input_token_details?.cached_tokens || 0),
                text:
                  prev.input.text +
                  (usage.input_token_details?.text_tokens || 0),
                audio:
                  prev.input.audio +
                  (usage.input_token_details?.audio_tokens || 0),
              },
              output: { ...prev.output },
            };

            // Calculate and update costs
            setCosts(calculateCosts(newCounts));

            return newCounts;
          });
        }
      } else if (realtimeEvent.event.type === 'response.done') {
        const usage = realtimeEvent.event.response?.usage;
        console.log('Response usage:', usage);

        if (usage) {
          setTokenCounts((prev) => {
            const newCounts = {
              input: { ...prev.input },
              output: {
                cached:
                  prev.output.cached +
                  (usage.output_token_details?.cached_tokens || 0),
                text:
                  prev.output.text +
                  (usage.output_token_details?.text_tokens || 0),
                audio:
                  prev.output.audio +
                  (usage.output_token_details?.audio_tokens || 0),
              },
            };

            // Calculate and update costs
            setCosts(calculateCosts(newCounts));

            return newCounts;
          });
        }
      }
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  /**
   * Add effect to get available microphones
   */
  useEffect(() => {
    async function getMicrophones() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter((device) => device.kind === 'audioinput');
      setAvailableMicrophones(mics);
      setSelectedMicrophone(mics[0]);
    }
    getMicrophones();
  }, []);

  const [isOptionsPanelOpen, setIsOptionsPanelOpen] = useState(false);

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="content-top">
        <div className="content-title">
          <div className="title-left">
            <img src="/pizza-hut-logo.png" />
            <span>Order Assistant</span>
          </div>
          <div className="title-right">
            Total Cost: €{costs.total.toFixed(4)}
          </div>
        </div>
      </div>
      <div className="content-main">
        <div className="content-logs">
          <div className="content-block">
            <div className="content-block-title">conversation</div>
            <div className="content-block-body" data-conversation-content>
              {!items.length && `awaiting connection...`}
              {items.map((conversationItem, i) => {
                return (
                  <div className="conversation-item" key={conversationItem.id}>
                    <div className={`speaker ${conversationItem.role || ''}`}>
                      <div>
                        {(
                          conversationItem.role || conversationItem.type
                        ).replaceAll('_', ' ')}
                      </div>
                      <div
                        className="close"
                        onClick={() =>
                          deleteConversationItem(conversationItem.id)
                        }
                      >
                        <X />
                      </div>
                    </div>
                    <div className={`speaker-content`}>
                      {/* tool response */}
                      {conversationItem.type === 'function_call_output' && (
                        <div>{conversationItem.formatted.output}</div>
                      )}
                      {/* tool call */}
                      {!!conversationItem.formatted.tool && (
                        <div>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </div>
                      )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                  '(item sent)')}
                          </div>
                        )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div>
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              '(truncated)'}
                          </div>
                        )}
                      {conversationItem.formatted.file && (
                        <audio
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="content-block">
            <div className="content-block-title">events</div>
            <div className="content-block-body" ref={eventsScrollRef}>
              {!realtimeEvents.length && `awaiting connection...`}
              {realtimeEvents.map((realtimeEvent, i) => {
                const count = realtimeEvent.count;
                const event = { ...realtimeEvent.event };
                if (event.type === 'input_audio_buffer.append') {
                  event.audio = `[trimmed: ${event.audio.length} bytes]`;
                } else if (event.type === 'response.audio.delta') {
                  event.delta = `[trimmed: ${event.delta.length} bytes]`;
                }
                return (
                  <div className="event" key={event.event_id}>
                    <div className="event-timestamp">
                      {formatTime(realtimeEvent.time)}
                    </div>
                    <div className="event-details">
                      <div
                        className="event-summary"
                        onClick={() => {
                          // toggle event details
                          const id = event.event_id;
                          const expanded = { ...expandedEvents };
                          if (expanded[id]) {
                            delete expanded[id];
                          } else {
                            expanded[id] = true;
                          }
                          setExpandedEvents(expanded);
                        }}
                      >
                        <div
                          className={`event-source ${
                            event.type === 'error'
                              ? 'error'
                              : realtimeEvent.source
                          }`}
                        >
                          {realtimeEvent.source === 'client' ? (
                            <ArrowUp />
                          ) : (
                            <ArrowDown />
                          )}
                          <span>
                            {event.type === 'error'
                              ? 'error!'
                              : realtimeEvent.source}
                          </span>
                        </div>
                        <div className="event-type">
                          {event.type}
                          {count && ` (${count})`}
                        </div>
                      </div>
                      {!!expandedEvents[event.event_id] && (
                        <div className="event-payload">
                          {JSON.stringify(event, null, 2)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="visualization">
          <div className="visualization-entry client">
            <canvas ref={clientCanvasRef} />
          </div>
          <div className="visualization-entry server">
            <canvas ref={serverCanvasRef} />
          </div>
        </div>
        <div className="content-actions">
          {isConnected && canPushToTalk && (
            <Button
              label={isRecording ? 'release to send' : 'push to talk'}
              buttonStyle={isRecording ? 'alert' : 'regular'}
              disabled={!isConnected || !canPushToTalk}
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
            />
          )}
          <Button
            label={isConnected ? 'disconnect' : 'connect'}
            iconPosition={isConnected ? 'end' : 'start'}
            icon={isConnected ? X : Zap}
            buttonStyle={isConnected ? 'regular' : 'action'}
            onClick={isConnected ? disconnectConversation : connectConversation}
          />
          <Button
            icon={Settings}
            buttonStyle="regular"
            onClick={() => setIsOptionsPanelOpen(!isOptionsPanelOpen)}
          />
        </div>
      </div>
      <div className={`options-panel ${isOptionsPanelOpen ? 'open' : ''}`}>
        <div className="options-panel-header">
          <Button
            icon={X}
            buttonStyle="regular"
            onClick={() => setIsOptionsPanelOpen(false)}
          />
        </div>
        <div className="token-counts">
          <div>
            <strong>Input Tokens:</strong> Text: {tokenCounts.input.text} |
            Audio: {tokenCounts.input.audio} | Cached:{' '}
            {tokenCounts.input.cached}
          </div>
          <div>
            <strong>Output Tokens:</strong> Text: {tokenCounts.output.text} |
            Audio: {tokenCounts.output.audio}
          </div>
          <div>
            <strong>Costs:</strong> Input: €{costs.input.toFixed(4)} | Output: €
            {costs.output.toFixed(4)}
          </div>
        </div>
        <div className="controls-container">
          <Toggle
            defaultValue={false}
            labels={['voice-activated', 'push-to-talk']}
            values={['server_vad', 'none']}
            onChange={(checked: boolean, value: string) => {
              changeTurnEndType(value as 'server_vad' | 'none');
            }}
          />
          {availableMicrophones.length > 1 && (
            <Dropdown
              options={availableMicrophones.map((mic) => ({
                value: mic.deviceId,
                label: mic.label || `Microphone ${mic.deviceId}`,
              }))}
              value={selectedMicrophone?.deviceId || ''}
              onChange={(value: string) => {
                const mic = availableMicrophones.find(
                  (m) => m.deviceId === value
                );
                setSelectedMicrophone(mic || null);
              }}
              placeholder="Select Microphone"
            />
          )}
        </div>
      </div>
    </div>
  );
}
